
export interface AliceSession {
  id: string;
  title: string;
  messages: AliceMessage[];
}

export interface AliceMessage {
  id: string;
  prompt: string;
  answer: string;
  liked: boolean;
  notLiked: boolean;
  comment: string;
  traceId: string;
  videoId?: string;
  timestamp?: string;
  flowsurveyId?: string;
}

export interface AliceKeyValueConfig {
  id: string;
  name: string;
  value: string;
  type: AliceKeyValueConfigEnum;
  externalConnector?: AliceKeyValueExternalConnector;
}

export interface AliceKeyValueExternalConnector {
  type: AliceKeyValueExternalConnectorTypeEnum;
  id: string;
  clientId: string;
  datasetName: string;
  datasetDescription: string;
  googleCloudApiKey: string;
  googleCloudProjectId: string;
}

export enum AliceKeyValueExternalConnectorTypeEnum {
  BIG_QUERY = "BIG_QUERY"
}

export enum AliceKeyValueConfigEnum {
  TABLE_DEFINITION = 'TABLE_DEFINITION',
  EXTERNAL_CONNECTOR = 'EXTERNAL_CONNECTOR',
  EXCLUDE_PAGES_FROM_INDEX = 'EXCLUDE_PAGES_FROM_INDEX',
  NOT_TO_INDEX = 'NOT_TO_INDEX'
}

export interface AliceTraceFilter {
  clientId?: string,
  userId?: string,
  startedAt?: string,
  finishedAt?: string,
  liked?: boolean,
  notLiked?: boolean
}

export interface AliceTraceSummary {
  userId: string;
  id: string;
  input: string;
  output: string;
  totalTokens: number;
  startedAt: string;
  finishedAt: string;
  latency: number;
}

export interface AliceTraceItem extends AliceTraceSummary {
  modelProvider: string;
  modelName: string;
  type: string;
  inputTokens: number;
  outputTokens: number;

  inputParsed?: any
  outputParsed?: any
}

export interface AliceTrace extends AliceTraceItem {
  items: AliceTraceItem[];
}

export interface ToolExecutionRequest {
  id: string;
  name: string;
  arguments: any;
}

export interface Message {
  type: string;
  text?: string;
  name?: string;
  contents?: { text: string }[];
  toolExecutionRequests?: ToolExecutionRequest[];
}

